module Main exposing (..)

import Array exposing (Array)
import Browser
import Browser.Navigation as Nav
import Html exposing (Html, button, div, text)
import Html.Attributes as HA
import Html.Events exposing (onClick, onInput)
import Http
import Json.Decode as D
import Json.Encode as E
import List.Extra as LE exposing (zip)
import RemoteData exposing (WebData)
import Route exposing (Route)
import Url exposing (Url)


type alias EventId =
    String


type alias TaskId =
    String


type alias VolunteerId =
    String


type alias Volunteer =
    { id : VolunteerId
    , name : String
    , task : TaskId
    , event : EventId
    , taken : Bool
    }


type alias Task =
    { id : TaskId
    , name : String
    , description : String
    , date : String
    , start_time : String
    , end_time : String
    , num_volunteers : Int
    }


type alias Event =
    { id : EventId
    , name : String
    , description : String
    , location : String
    }


type alias EventDetail =
    { event : EventId
    , name : String
    , description : String
    , location : String
    , tasks : List Task
    , volunteers : List Volunteer
    }


main : Program () Model Msg
main =
    Browser.application
        { init = init
        , update = update
        , view = view
        , subscriptions = subscriptions
        , onUrlChange = ChangedUrl
        , onUrlRequest = ClickedLink
        }


type Model
    = Redirect Nav.Key
    | NotFound Nav.Key
    | Events Nav.Key (WebData (List Event))
    | EventInfo Nav.Key (WebData EventDetail)
    | CreateEventPage Nav.Key ( Event, Array Task )


init : flags -> Url -> Nav.Key -> ( Model, Cmd Msg )
init _ url nk =
    changeRouteTo (Route.fromUrl url)
        (Redirect nk)


type Msg
    = EventsReceived (WebData (List Event))
    | EventDetailsReceived (WebData EventDetail)
    | ChangedUrl Url
    | ClickedLink Browser.UrlRequest
    | UpdateVolunteer String String
    | AddVolunteer EventId TaskId String
    | AddedVolunteer (Result Http.Error Volunteer)
    | DeleteVolunteer VolunteerId
    | DeletedVolunteer (Result Http.Error ())
    | AddTask
    | RemoveCreatedTask String
    | SetEventName String
    | SetEventDescription String
    | SetEventLocation String
    | SetTaskName TaskId String
    | SetTaskDescription TaskId String
    | SetTaskDate TaskId String
    | SetTaskStart TaskId String
    | SetTaskEnd TaskId String
    | SetTaskNumVolunteers TaskId String
    | CreateEvent
    | CreatedEvent (Result Http.Error ())


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    let
        nk =
            navKey model
    in
    case msg of
        EventsReceived events ->
            ( Events nk events, Cmd.none )

        EventDetailsReceived details ->
            ( EventInfo nk (RemoteData.map preFill details), Cmd.none )

        ClickedLink urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    ( model, Nav.pushUrl nk (Url.toString url) )

                Browser.External href ->
                    ( model, Nav.load href )

        ChangedUrl url ->
            changeRouteTo (Route.fromUrl url) model

        UpdateVolunteer volunteerId name ->
            ( setVolunteerName model volunteerId name, Cmd.none )

        AddVolunteer event task name ->
            ( model, addVolunteer event task name )

        AddedVolunteer _ ->
            ( model, Nav.reload )

        DeleteVolunteer vid ->
            ( model, deleteVolunteer vid )

        DeletedVolunteer _ ->
            ( model, Nav.reload )

        AddTask ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk ( event, addNewTask tasks ), Cmd.none )

                _ ->
                    ( model, Cmd.none )

        RemoveCreatedTask taskid ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk ( event, Array.filter (\task -> task.id /= taskid) tasks )
                    , Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        SetEventName name ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk ( { event | name = name }, tasks ), Cmd.none )

                _ ->
                    ( model, Cmd.none )

        SetEventDescription description ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk ( { event | description = description }, tasks ), Cmd.none )

                _ ->
                    ( model, Cmd.none )

        SetEventLocation location ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk ( { event | location = location }, tasks ), Cmd.none )

                _ ->
                    ( model, Cmd.none )

        SetTaskName task name ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk ( event, setTaskName task name tasks ), Cmd.none )

                _ ->
                    ( model, Cmd.none )

        SetTaskDescription task description ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk
                        ( event, setTaskDescription task description tasks )
                    , Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        SetTaskDate task date ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk
                        ( event, setTaskDate task date tasks )
                    , Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        SetTaskStart task start ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk
                        ( event, setTaskStart task start tasks )
                    , Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        SetTaskEnd task end ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk
                        ( event, setTaskEnd task end tasks )
                    , Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        SetTaskNumVolunteers task num_volunteers ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( CreateEventPage nk
                        ( event, setTaskNumVolunteers task num_volunteers tasks )
                    , Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        CreateEvent ->
            case model of
                CreateEventPage _ ( event, tasks ) ->
                    ( model, createEvent event tasks )

                _ ->
                    ( model, Cmd.none )

        CreatedEvent _ ->
            ( model, Nav.pushUrl nk "/" )


view : Model -> Browser.Document Msg
view model =
    { title = "Ik Schrijf In"
    , body =
        [ case model of
            Redirect _ ->
                text "redirecting"

            NotFound _ ->
                text "404"

            Events _ events ->
                viewEvents events

            EventInfo _ detail ->
                viewEventDetail detail

            CreateEventPage _ ( event, tasks ) ->
                viewCreateEventPage event tasks
        ]
    }


subscriptions : Model -> Sub Msg
subscriptions =
    always Sub.none


newEvent : Event
newEvent =
    { id = ""
    , name = ""
    , description = ""
    , location = ""
    }


addNewTask : Array Task -> Array Task
addNewTask tasks =
    let
        idFromTask : Task -> Maybe String -> Maybe String
        idFromTask task acc =
            Maybe.map2 (\a b -> String.fromInt (1 + max a b))
                (String.toInt task.id)
                (Maybe.andThen String.toInt acc)

        id : String
        id =
            Array.foldl idFromTask (Just "0") tasks
                |> Maybe.withDefault "0"
    in
    Array.push
        { id = id
        , name = ""
        , description = ""
        , date = ""
        , start_time = ""
        , end_time = ""
        , num_volunteers = 0
        }
        tasks


setTaskName : TaskId -> String -> Array Task -> Array Task
setTaskName taskid name tasks =
    let
        updateName : Task -> Task
        updateName task =
            if task.id == taskid then
                { task | name = name }

            else
                task
    in
    Array.map updateName tasks


setTaskDescription : TaskId -> String -> Array Task -> Array Task
setTaskDescription taskid description tasks =
    let
        updateDescription : Task -> Task
        updateDescription task =
            if task.id == taskid then
                { task | description = description }

            else
                task
    in
    Array.map updateDescription tasks


setTaskDate : TaskId -> String -> Array Task -> Array Task
setTaskDate taskid date tasks =
    let
        updateDate : Task -> Task
        updateDate task =
            if task.id == taskid then
                { task | date = date }

            else
                task
    in
    Array.map updateDate tasks


setTaskStart : TaskId -> String -> Array Task -> Array Task
setTaskStart taskid start tasks =
    let
        updateStart : Task -> Task
        updateStart task =
            if task.id == taskid then
                { task | start_time = start }

            else
                task
    in
    Array.map updateStart tasks


setTaskEnd : TaskId -> String -> Array Task -> Array Task
setTaskEnd taskid end tasks =
    let
        updateEnd : Task -> Task
        updateEnd task =
            if task.id == taskid then
                { task | end_time = end }

            else
                task
    in
    Array.map updateEnd tasks


setTaskNumVolunteers : TaskId -> String -> Array Task -> Array Task
setTaskNumVolunteers taskid num_volunteers tasks =
    let
        updateNumVolunteers : Int -> Task -> Task
        updateNumVolunteers n task =
            if task.id == taskid then
                { task | num_volunteers = n }

            else
                task
    in
    case String.toInt num_volunteers of
        Just n ->
            Array.map (updateNumVolunteers n) tasks

        _ ->
            tasks



---------------


setVolunteerName : Model -> String -> String -> Model
setVolunteerName model volunteerId name =
    let
        setVolunteerNameImpl : EventDetail -> EventDetail
        setVolunteerNameImpl details =
            { details
                | volunteers =
                    List.map
                        (\volunteer ->
                            if volunteer.id == volunteerId then
                                { volunteer | name = name }

                            else
                                volunteer
                        )
                        details.volunteers
            }
    in
    case model of
        EventInfo key details ->
            EventInfo key (RemoteData.map setVolunteerNameImpl details)

        _ ->
            model


viewEvents : WebData (List Event) -> Html Msg
viewEvents data =
    Html.main_ [ HA.class "main-page" ]
        [ Html.div [ HA.class "main-header" ] [ Html.h1 [] [ text "Ik Schrijf In" ] ]
        , Html.h2 [] [ text "Alle evenementen" ]
        , Html.a [ HA.href "create" ] [ text "Maak een evenement" ]
        , Html.ul
            [ HA.class "event-list" ]
            (case data of
                RemoteData.NotAsked ->
                    [ text "Initializing..." ]

                RemoteData.Loading ->
                    [ text "Loading..." ]

                RemoteData.Success events ->
                    List.map viewEvent events

                RemoteData.Failure err ->
                    [ text ("Error: " ++ errorToString err) ]
            )
        ]


viewEvent : Event -> Html Msg
viewEvent event =
    Html.li [ HA.class "event" ]
        [ Html.a [ HA.href event.id ]
            [ Html.h3 [] [ text event.name ]
            , Html.p [] [ text event.description ]
            , Html.div [] [ text ("📍 " ++ event.location) ]
            ]
        ]


preFill : EventDetail -> EventDetail
preFill details =
    let
        volunteers : List Volunteer
        volunteers =
            details.tasks
                |> LE.andThen
                    (\task ->
                        List.repeat
                            (task.num_volunteers - List.length (List.filter (\v -> v.task == task.id) details.volunteers))
                            (Volunteer "" "" task.id details.event False)
                    )
                |> List.indexedMap (\index volunteer -> { volunteer | id = String.fromInt index })
    in
    { details | volunteers = details.volunteers ++ volunteers }


viewEventDetail : WebData EventDetail -> Html Msg
viewEventDetail data =
    case data of
        RemoteData.NotAsked ->
            text "Initializing..."

        RemoteData.Loading ->
            text "Loading..."

        RemoteData.Failure err ->
            text ("Error: " ++ errorToString err)

        RemoteData.Success details ->
            div []
                [ Html.a [ HA.href "/" ] [ text "All Events" ]
                , Html.h1 [] [ text ("Event: " ++ details.name) ]
                , Html.h2 [] [ text details.description ]
                , Html.h3 [] [ text ("Location: " ++ details.location) ]
                , Html.ul []
                    (List.map
                        (\task ->
                            Html.li []
                                [ viewTask task
                                    (List.filter (\v -> v.task == task.id) details.volunteers)
                                ]
                        )
                        details.tasks
                    )
                ]


viewTask : Task -> List Volunteer -> Html Msg
viewTask task volunteers =
    Html.p []
        ([ text (task.name ++ ": " ++ task.description)
         , Html.br [] []
         , text (task.date ++ " @ " ++ task.start_time ++ " -- " ++ task.end_time)
         , Html.br [] []
         , text
            (String.fromInt (task.num_volunteers - List.length volunteers)
                ++ " of "
                ++ String.fromInt task.num_volunteers
                ++ " volunteers required"
            )
         , Html.br [] []
         ]
            ++ List.map viewVolunteer volunteers
        )


viewVolunteer : Volunteer -> Html Msg
viewVolunteer volunteer =
    if volunteer.taken then
        Html.span
            []
            [ Html.input [ HA.disabled True, HA.value volunteer.name ] []
            , button [ onClick (DeleteVolunteer volunteer.id) ] [ text "🗑" ]
            , Html.br [] []
            ]

    else
        Html.span
            []
            [ Html.input
                [ HA.type_ "text"
                , HA.value volunteer.name
                , onInput (UpdateVolunteer volunteer.id)
                ]
                []
            , button
                [ onClick
                    (AddVolunteer volunteer.event volunteer.task volunteer.name)
                ]
                [ text "➜" ]
            , Html.br [] []
            ]


viewCreateEventPage : Event -> Array Task -> Html Msg
viewCreateEventPage event tasks =
    Html.div []
        [ Html.h1 [] [ text "Create an event" ]
        , Html.div [] [ Html.a [ HA.href "/" ] [ text "Cancel" ] ]
        , Html.div []
            [ Html.label [ HA.for "event-name" ] [ text "Name" ]
            , Html.input
                [ HA.id "event-name"
                , HA.type_ "text"
                , HA.value event.name
                , onInput SetEventName
                ]
                []
            ]
        , Html.textarea
            [ HA.placeholder "description"
            , HA.value event.description
            , HA.rows 10
            , HA.cols 35
            , onInput SetEventDescription
            ]
            []
        , Html.div []
            [ Html.label [ HA.for "event-location" ] [ text "location" ]
            , Html.input
                [ HA.id "event-location"
                , HA.type_ "text"
                , HA.value event.location
                , onInput SetEventLocation
                ]
                []
            ]
        , viewCreatedTasks tasks
        , Html.button [ onClick AddTask ] [ text "Add a task" ]
        , Html.button [ onClick CreateEvent ] [ text "Create the event" ]
        ]


viewCreatedTasks : Array Task -> Html Msg
viewCreatedTasks tasks =
    Html.div
        [ HA.style "display" "flex"
        , HA.style "flex-direction" "column"
        ]
        (Array.toList (Array.map viewCreatedTask tasks))


viewCreatedTask : Task -> Html Msg
viewCreatedTask task =
    Html.div
        [ HA.style "display" "flex" ]
        [ Html.div
            [ HA.style "display" "flex"
            , HA.style "flex-direction" "column"
            ]
            [ Html.input
                [ HA.type_ "text"
                , HA.placeholder "name"
                , HA.value task.name
                , onInput (SetTaskName task.id)
                ]
                []
            , Html.input
                [ HA.type_ "text"
                , HA.placeholder "description"
                , HA.value task.description
                , onInput (SetTaskDescription task.id)
                ]
                []
            , Html.input
                [ HA.type_ "text"
                , HA.placeholder "date"
                , HA.value task.date
                , onInput (SetTaskDate task.id)
                ]
                []
            , Html.input
                [ HA.type_ "text"
                , HA.placeholder "starting time"
                , HA.value task.start_time
                , onInput (SetTaskStart task.id)
                ]
                []
            , Html.input
                [ HA.type_ "text"
                , HA.placeholder "ending time"
                , HA.value task.end_time
                , onInput (SetTaskEnd task.id)
                ]
                []
            , Html.input
                [ HA.type_ "number"
                , HA.value (String.fromInt task.num_volunteers)
                , onInput (SetTaskNumVolunteers task.id)
                ]
                []
            ]
        , Html.button [ onClick (RemoveCreatedTask task.id) ] [ text "Remove task" ]
        ]


getEvents : Cmd Msg
getEvents =
    Http.request
        { method = "GET"
        , headers = [ Http.header "Content-Type" "application/json" ]
        , url = "https://volunteer-app-3ysw9.ondigitalocean.app/events"
        , body = Http.emptyBody
        , expect = Http.expectJson (RemoteData.fromResult >> EventsReceived) (D.list eventDecoder)
        , timeout = Just 5000
        , tracker = Nothing
        }


getEvent : EventId -> Cmd Msg
getEvent eventid =
    Http.request
        { method = "GET"
        , headers = []
        , url = "https://volunteer-app-3ysw9.ondigitalocean.app/event/" ++ eventid
        , body = Http.emptyBody
        , expect = Http.expectJson (RemoteData.fromResult >> EventDetailsReceived) eventDetailDecoder
        , timeout = Just 5000
        , tracker = Nothing
        }


addVolunteer : EventId -> TaskId -> String -> Cmd Msg
addVolunteer event task name =
    Http.request
        { method = "POST"
        , headers = []
        , url =
            "https://volunteer-app-3ysw9.ondigitalocean.app/event/"
                ++ event
                ++ "/task/"
                ++ task
                ++ "/volunteer"
        , body = Http.jsonBody (E.object [ ( "name", E.string name ) ])
        , expect = Http.expectJson AddedVolunteer volunteerDecoder
        , timeout = Just 5000
        , tracker = Nothing
        }


deleteVolunteer : VolunteerId -> Cmd Msg
deleteVolunteer volunteer =
    Http.request
        { method = "DELETE"
        , headers = []
        , url =
            "https://volunteer-app-3ysw9.ondigitalocean.app/volunteer/" ++ volunteer
        , body = Http.emptyBody
        , expect = Http.expectWhatever DeletedVolunteer
        , timeout = Just 5000
        , tracker = Nothing
        }


createEvent : Event -> Array Task -> Cmd Msg
createEvent event tasks =
    Http.request
        { method = "POST"
        , headers = []
        , url = "https://volunteer-app-3ysw9.ondigitalocean.app/event"
        , body =
            Http.jsonBody
                (E.object
                    [ ( "name", E.string event.name )
                    , ( "description", E.string event.description )
                    , ( "location", E.string event.location )
                    , ( "tasks", E.array encodeTask tasks )
                    ]
                )
        , expect = Http.expectWhatever CreatedEvent
        , timeout = Just 5000
        , tracker = Nothing
        }


encodeTask : Task -> E.Value
encodeTask task =
    E.object
        [ ( "name", E.string task.name )
        , ( "description", E.string task.description )
        , ( "date", E.string task.date )
        , ( "start_time", E.string task.start_time )
        , ( "end_time", E.string task.end_time )
        , ( "num_volunteers", E.int task.num_volunteers )
        ]


eventDecoder : D.Decoder Event
eventDecoder =
    D.map4 Event
        (D.field "id" D.string)
        (D.field "name" D.string)
        (D.field "description" D.string)
        (D.field "location" D.string)


eventDetailDecoder : D.Decoder EventDetail
eventDetailDecoder =
    D.map6 EventDetail
        (D.field "event" D.string)
        (D.field "name" D.string)
        (D.field "description" D.string)
        (D.field "location" D.string)
        (D.field "tasks" (D.list taskDecoder))
        (D.field "volunteers" (D.list volunteerDecoder))


taskDecoder : D.Decoder Task
taskDecoder =
    D.map7 Task
        (D.field "id" D.string)
        (D.field "name" D.string)
        (D.field "description" D.string)
        (D.field "date" D.string)
        (D.field "start_time" D.string)
        (D.field "end_time" D.string)
        (D.field "num_volunteers" D.int)


volunteerDecoder : D.Decoder Volunteer
volunteerDecoder =
    D.map5 Volunteer
        (D.field "id" D.string)
        (D.field "name" D.string)
        (D.field "task" D.string)
        (D.field "event" D.string)
        (D.succeed True)


errorToString : Http.Error -> String
errorToString err =
    case err of
        Http.BadUrl url ->
            url ++ " is not a valid url"

        Http.Timeout ->
            "Connection timed out"

        Http.NetworkError ->
            "A network error occured"

        Http.BadStatus status ->
            "Error: " ++ String.fromInt status

        Http.BadBody error ->
            "Error decoding body of response: " ++ error


navKey : Model -> Nav.Key
navKey model =
    case model of
        Redirect key ->
            key

        NotFound key ->
            key

        Events key _ ->
            key

        EventInfo key _ ->
            key

        CreateEventPage key _ ->
            key


changeRouteTo : Maybe Route -> Model -> ( Model, Cmd Msg )
changeRouteTo maybeRoute model =
    let
        key =
            navKey model
    in
    case maybeRoute of
        Nothing ->
            ( NotFound key, Cmd.none )

        Just Route.Home ->
            ( Events key RemoteData.Loading, getEvents )

        Just (Route.Event eventid) ->
            ( EventInfo key RemoteData.Loading, getEvent eventid )

        Just Route.CreateEvent ->
            ( CreateEventPage key ( newEvent, Array.empty ), Cmd.none )
